.transporter-preference-operation-city-section {
  display: flex;
  flex-direction: column;
  margin: 2% 2% 0 2%;
}

.transporter-preference-operation-city-label {
  font-size: 14px;
  color: #333;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1%;
}

.transporter-preference-city-input-section {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #c6c6c6;
  padding-left: 1%;
}

.transporter-expanded-accordian-pill-container-input-box {
  border: 1px solid #c6c6c6;
  border-top: none;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.transporter-preference-sub-city-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5%;
  display: flex;
  gap: 0.5%;
}

.transporter-preference-input-dropdown-container {
  position: relative;
  width: 100%;
}

.transporter-preference-dropdown-header {
  width: 100%;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid #c6c6c6;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
}

.scrollable-list {
  max-height: 150px;
  overflow-y: auto;
}

.scrollable-list-container {
  position: relative;
  cursor: pointer;
}

.scrollable-list {
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #c6c6c6;
  border-top: none;
  z-index: 1;
  width: 100%;
}

.padding-box {
  padding: 5px 10px;
}

.transporter-preference-dropdown-menu {
  box-sizing: border-box;
  position: absolute;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #c6c6c6;
  border-top: none;
  background-color: #fff;
  z-index: 5;
  font-weight: 700 !important;
  color: #333333;
}

.transporter-preference-dropdown-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 4px;
  border-radius: 5px;
}

.transporter-preference-dropdown-menu-item:hover {
  background-color: #f2f2f2;
}

.transporter-preference-dropdown-menu-item.selected {
  background-color: #dde7ef;
  color: #333333;
}

.transporter-preference-ticket-select-text {
  color: #adadad;
  font-size: 16px;
}

.transporter-expanded-accordian-pill-btn {
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  background: #dde7ef;
  margin: 2px;
  gap: 8px;
  border-radius: 3px;
  border: 1px solid #5386e4;
}

.remove-city-btn {
  border: none;
  color: #ff3f3f;
  font-size: 14px;
  font-weight: 700;
  background: #ffffff;
  position: fixed;
  margin-left: 20px;
}

.submit-btn {
  width: 125px;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: #0c7f44;
  border-radius: 5px;
  border: none;
  position: fixed;
}

.transporter-add-edit-cities-header {
  width: 100%;
  background: #EAEAEA;
  height: 48px;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1%;
  gap: 40%;
}
.MuiAutocomplete-inputRoot{
  padding: 0px !important;
}
